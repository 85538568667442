<template>
  <div class="column" ref="tabLay">
    <div class="top1">
      <div class="row_normal" @click="back">
        <img class="back" src="../../assets/img/course/Icon_Back.png" />
        <div class="go_home">返回首页</div>
      </div>
    </div>
    <div class="top2">
      <div class="select ml235" @click="choiceClick(4)">课程中心</div>
      <div class="normal ml8" @click="choiceClick(5)">测试中心</div>
      <div class="normal ml8" @click="choiceClick(6)">科技中心</div>
      <div class="normal ml8" @click="choiceClick(7)">信息中心</div>
      <div class="normal ml8" @click="choiceClick(8)">账号设置</div>
    </div>
    <div class="row_center top_label">
      <div class="top_column" @click="clickLable(1)">
        <img
          v-if="labelType == 1"
          class="top_icon"
          src="../../assets/img/course/Icon_LGA.png"
        />
        <img
          v-else
          class="top_icon"
          src="../../assets/img/course/Icon_PGS.png"
        />
        <div class="mt7 text">Les Get Active</div>
        <div class="mt5 text">幼儿足球游戏基础课程</div>
      </div>
      <div class="top_column ml40" @click="clickLable(2)">
        <img
          v-if="labelType == 2"
          class="top_icon"
          src="../../assets/img/course/Icon_LGA.png"
        />
        <img
          v-else
          class="top_icon"
          src="../../assets/img/course/Icon_PGS.png"
        />
        <div class="mt7 text">Physical Game Session</div>
        <div class="mt5 text">幼儿足球游戏专题课程</div>
      </div>
      <div class="top_column ml36" @click="clickLable(3)">
        <img
          v-if="labelType == 3"
          class="top_icon"
          src="../../assets/img/course/Icon_LGA.png"
        />
        <img
          v-else
          class="top_icon"
          src="../../assets/img/course/Logo_PGL.png"
        />
        <div class="mt7 text">Prefootball Game Library</div>
        <div class="mt5 text">幼儿足球游戏样例库</div>
      </div>
      <div class="top_column ml26" @click="clickLable(4)">
        <img
          v-if="labelType == 4"
          class="top_icon"
          src="../../assets/img/course/Icon_LGA.png"
        />
        <img v-else class="top_icon" src="../../assets/img/course/PHG.png" />
        <div class="mt7 text">Prefootball Home Game</div>
        <div class="mt5 text">幼儿足球游戏家庭课程</div>
      </div>
    </div>
    <div class="h192"></div>
    <div class="column_center">
      <div class="video_bg pos_rel" id="lgaId">
        <swiper
          :options="swiperOption"
          ref="mySwiper"
          @slideChange="slideChange"
        >
          <swiper-slide>
            <div class="swiper-box">
              <img src="../../assets/img/home/home/Pic_GYY_Girl.png" />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiper-box">
              <img src="../../assets/img/home/home/Pic_GYY_Girl1.png" />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiper-box">
              <img src="../../assets/img/home/home/Pic_SFY_Boy.png" />
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <div class="column_center pos_abs z11">
          <img
            class="lga_image"
            src="../../assets/img/course/Les_Get_Active.png"
          />
          <div class="lga_text">幼儿足球游戏基础课程</div>
          <div class="lga_remark">
            <div>
              xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            </div>
            <div>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</div>
          </div>
          <div class="row mt38">
            <div class="lga_pk">给幼儿配课</div>
            <div class="lga_jd">上课进度及评价</div>
            <div class="lga_ja">课程教案</div>
          </div>
        </div>
      </div>
      <div class="video_bg pos_rel" id="pgsId">
        <swiper
          :options="swiperOption"
          ref="mySwiper"
          @slideChange="slideChange"
        >
          <swiper-slide>
            <div class="swiper-box">
              <img src="../../assets/img/home/home/Pic_GYY_Girl.png" />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiper-box">
              <img src="../../assets/img/home/home/Pic_GYY_Girl1.png" />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiper-box">
              <img src="../../assets/img/home/home/Pic_SFY_Boy.png" />
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <div class="column_center pos_abs z11">
          <img
            class="pgs_image"
            src="../../assets/img/course/Physical_Game_Session.png"
          />
          <div class="pgs_text">幼儿足球游戏专题课程</div>
          <div class="lga_remark">
            <div>
              xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            </div>
            <div>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</div>
          </div>
          <div class="row mt38">
            <div class="pgs_kc">宋振军教练课程</div>
            <div class="pgs_kc ml10">张梁教练课程</div>
            <div class="pgs_kc ml10">张凯教练课程</div>
          </div>
        </div>
      </div>
      <div class="video_bg pos_rel" id="pglId">
        <swiper
          :options="swiperOption"
          ref="mySwiper"
          @slideChange="slideChange"
        >
          <swiper-slide>
            <div class="swiper-box">
              <img src="../../assets/img/home/home/Pic_GYY_Girl.png" />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiper-box">
              <img src="../../assets/img/home/home/Pic_GYY_Girl1.png" />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiper-box">
              <img src="../../assets/img/home/home/Pic_SFY_Boy.png" />
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <div class="column_center pos_abs z11">
          <img
            class="pgl_image"
            src="../../assets/img/course/Prefootball_Game_Library.png"
          />
          <div class="pgs_text">幼儿体育游戏库</div>
          <div class="lga_remark">
            <div>
              xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            </div>
            <div>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</div>
          </div>
          <div class="row mt38">
            <div class="pgl_yxk">查看游戏库</div>
            <div class="pgl_add_l">
              <img class="pgl_add" src="../../assets/img/course/Icon_Add.png" />
              <div class="ml24">添加课程</div>
            </div>
          </div>
        </div>
      </div>
      <div class="video_bg pos_rel" id="phgId">
        <swiper
          :options="swiperOption"
          ref="mySwiper"
          @slideChange="slideChange"
        >
          <swiper-slide>
            <div class="swiper-box">
              <img src="../../assets/img/home/home/Pic_GYY_Girl.png" />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiper-box">
              <img src="../../assets/img/home/home/Pic_GYY_Girl1.png" />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiper-box">
              <img src="../../assets/img/home/home/Pic_SFY_Boy.png" />
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <div class="column_center pos_abs z11">
          <img
            class="phg_image"
            src="../../assets/img/course/Prefootball_Home_Game.png"
          />
          <div class="lga_text">幼儿足球游戏基础课程</div>
          <div class="lga_remark">
            <div>
              xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            </div>
            <div>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</div>
          </div>
          <div class="row mt38">
            <div class="phg_kc">查看课程列表</div>
          </div>
        </div>
      </div>
    </div>
    <div class="h55"></div>
    <popUp @bindSet="setBillDetail" @bindSkip="setBillSkip" v-if="isRoute" />
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import popUp from "../zhSchool/components/popup/popQue.vue";
export default {
  name: "LGAHome",
  components: {
    popUp,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      tyVideo: {},
      video: {},
      alertShow: false,
      isRoute: false,
      swiperOption: {
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        speed: 3000,
      },
      swiperIndex: 1,
      labelType: 1,
    };
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    slideChange() {
      this.swiperIndex = this.$refs.mySwiper.swiper.activeIndex;
    },
    clickLable(index) {
      if (this.labelType != index) {
        this.labelType = index;
        if (index == 1) {
          document.getElementById("lgaId").scrollIntoView();
        } else if (index == 2) {
          document.getElementById("pgsId").scrollIntoView();
        } else if (index == 3) {
          document.getElementById("pglId").scrollIntoView();
        } else if (index == 4) {
          document.getElementById("phgId").scrollIntoView();
        }
      }
    },
    handleScroll() {
      var scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      let pgsId = document.getElementById("pgsId");
      let smallY = pgsId.offsetTop;
      let pglId = document.getElementById("pglId");
      let middleY = pglId.offsetTop;
      let phgId = document.getElementById("phgId");
      let largeY = phgId.offsetTop;
      if (scrollTop >= smallY - 200) {
        if (scrollTop >= middleY - 200) {
          if (scrollTop >= largeY - 200) {
            this.labelType = 4;
          } else {
            this.labelType = 3;
          }
        } else {
          this.labelType = 2;
        }
      } else {
        this.labelType = 1;
      }
    },
    play(item) {
      item.isLoad = true;
      item.isPlay = true;
      if (this.tyVideo != null) {
        this.tyVideo.play();
      } else {
        this.$nextTick(() => {
          var source = {};
          if (item.videoPaly.playInfoList) {
            item.videoPaly.playInfoList.forEach((item1) => {
              source[item1.definition] = item1.playURL;
            });
          }
          let player = new window.Aliplayer(
            {
              format: "m3u8",
              encryptType: "1",
              id: "tvideo" + item.id,
              source: JSON.stringify(source),
              defaultDefinition: "SD",
              autoplay: true,
              width: "100%",
              height: "100%",
              cover: item.videoPaly.videoBase.coverURL,
              isLive: false,
              rePlay: false,
              preload: true,
            },
            function (player) {
              console.log(player);
            }
          );
          player.on("play", function () {
            item.isPlay = true;
          });
          player.on("pause", function () {
            item.isPlay = false;
          });
          this.tyVideo = player;
        });
      }
    },
    choiceClick(index) {
      console.log(index);
      if (index == 0) {
        this.$router.push({
          path: "/LGAHome",
        });
      } else if (index == 1) {
        this.$router.push({
          path: "/PESHome",
        });
      }
    },
    clickYd() {
      this.$hint({
        type: "load",
        message: "敬请期待",
      }).isShow();
    },
    exitfull() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },

    setBillDetail() {
      this.isRoute = false;
    },
    getTyCourse() {
      let data = {
        courseId: 2,
        isPremium: 0,
      };
      this.api.pes.getLAGCourse(data).then((res) => {
        res.data.forEach((item) => {
          item.showAlert = false;
          item.isPlay = false;
          item.isLoad = false;
        });
        this.tyList = res.data;
      });
    },
    goHome() {
      this.$router.push({
        path: "/",
      });
    },
  },
  mounted() {
    // this.getTyCourse();
    let _this = this;
    window.onscroll = function () {
      _this.handleScroll();
    };
  },

  beforeDestroy() {},
  computed: {},
};
</script>
<style lang="scss" scoped>
.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #e7e7e7;
  overflow-x: hidden;
}
.top1 {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 999;
  width: 100%;
  height: 43px;
  background: #444444;
  padding-left: 318px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .text {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #d9e0df;
  }
}
.back {
    width: 9px;
    height: 15px;
  }
  .go_home {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #696969;
    margin-left: 5px;
  }
.top2 {
  position: fixed;
  left: 0px;
  top: 43px;
  z-index: 999;
  width: 100%;
  height: 43px;
  background: #333333;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 154px;
  padding-right: 308px;
  
  .normal {
    height: 34px;
    border: 2px solid #696969;
    border-radius: 17px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #d6d6d6;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .normal_t {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #d6d6d6;
    cursor: pointer;
  }
  .ml239 {
    margin-left: 239px;
  }
  .ml56 {
    margin-left: 56px;
  }
  .ml59 {
    margin-left: 59px;
  }
  .ml43 {
    margin-left: 43px;
  }
  .select {
    height: 34px;
    background: #ffffff;
    border-radius: 17px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #151071;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .ml235 {
    margin-left: 1009px;
  }
  .ml32 {
    margin-left: 32px;
  }
  .ml19 {
    margin-left: 19px;
  }
  .ml35 {
    margin-left: 35px;
  }
  .ml8 {
    margin-left: 8px;
  }
}
.h55 {
  height: 86px;
}
.h192 {
  height: 192px;
}
.top_label {
  position: fixed;
  top: 86px;
  left: 0px;
  z-index: 999;
}
.video_bg {
  width: 1302px;
  height: 582px;
  background: #007200;
  border-radius: 16px;
  margin-top: 43px;
}
.flex {
  flex: 1;
}
.row_center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 106px;
  background: white;
}
.row_normal {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.row {
  display: flex;
  align-content: center;
}
/deep/ .prism-player {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
/deep/ .prism-player .prism-cover {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
/deep/ .prism-player .prism-big-play-btn {
  width: 0px;
  height: 0px;
  position: absolute;
  right: 30px;
  bottom: 60px;
}
/deep/ .prism-player .prism-big-play-btn .outter {
  width: 0px;
  height: 0px;
  border: 0px solid rgba(255, 255, 255, 0.51);
}
.image_l {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  .image {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 16px;
  }
}
.column_center {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.top_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .top_icon {
    width: 30px;
    height: 30px;
  }
  .text {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
  }
  .mt7 {
    margin-top: 7px;
  }
  .mt5 {
    margin-top: 5px;
  }
}
.ml40 {
  margin-left: 40px;
}
.ml36 {
  margin-left: 36px;
}
.ml26 {
  margin-left: 26px;
}
.pos_rel {
  position: relative;
}
.pos_abs {
  position: absolute;
  top: 0;
  left: 0;
}
.z11 {
  z-index: 11;
}
.swiper-box {
  width: 100%;
  height: 582px;
  img {
    height: 582px;
    border-radius: 16px;
  }
}
/deep/ .swiper-pagination-bullets {
  bottom: 16px;
  right: 70px;
  left: auto;
}
/deep/ .swiper-pagination-bullet {
  width: 11px;
  height: 11px;
  background: white;
}
/deep/ .swiper-pagination-bullet-active {
  background: #85e200;
}
/deep/ .swiper-pagination {
  text-align: end;
}
.lga_image {
  width: 705px;
  height: 88px;
  margin-top: 275px;
}
.lga_text {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-top: 31px;
}
.lga_remark {
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-top: 11px;
  width: 633px;
  max-width: 633px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.pgs_image {
  width: 1162px;
  height: 109px;
  margin-top: 275px;
}
.pgl_image {
  width: 1192px;
  height: 101px;
  margin-top: 282px;
}
.phg_image {
  width: 1138px;
  height: 81px;
  margin-top: 282px;
}
.mt38 {
  margin-top: 18px;
}
.lga_pk {
  width: 172px;
  height: 38px;
  background: #ffffff;
  border: 0px solid #ffffff;
  border-radius: 19px;
  font-size: 21px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #002f00;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.lga_jd {
  width: 220px;
  height: 38px;
  background: #ffffff;
  border: 0px solid #ffffff;
  border-radius: 19px;
  font-size: 21px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #002f00;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
}
.lga_ja {
  width: 172px;
  height: 38px;
  background: #85e200;
  border: 0px solid #ffffff;
  border-radius: 19px;
  font-size: 21px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #002f00;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 71px;
  cursor: pointer;
}
.pgs_text {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-top: 10px;
}
.pgs_kc {
  width: 210px;
  height: 38px;
  background: #000000;
  border: 0px solid #ffffff;
  border-radius: 19px;
  font-size: 21px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ml10 {
  margin-left: 10px;
}
.pgl_yxk {
  width: 210px;
  height: 38px;
  background: #ceaca3;
  border: 0px solid #ffffff;
  border-radius: 19px;
  font-size: 21px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.pgl_add_l {
  width: 270px;
  height: 38px;
  background: #000000;
  border: 0px solid #ffffff;
  border-radius: 19px;
  font-size: 21px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 9px;
  cursor: pointer;
  .pgl_add {
    width: 87px;
    height: 26px;
  }
  .ml24 {
    margin-left: 24px;
  }
}
.phg_kc {
  width: 210px;
  height: 38px;
  background: #ffffff;
  border: 0px solid #ffffff;
  border-radius: 19px;
  font-size: 21px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #010000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>